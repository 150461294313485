/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import type { AnyAction, Dispatch } from 'redux';

import { LoginActions } from 'sarsaparilla';
import { accountsApi, ExternalUserAccount } from 'sarsaparilla/login';

import { apiHelper, endpoints } from '../api';
import { AuthLocalStorageKeys } from '../constants/auth';
import Auth, { AuthTargetedTypes } from '../services/auth';
import { LocalStorage } from '../services/localStorage';
import { keysToCamelCase } from './object';

export interface Session {
    account: Record<string, any>;
    access_token: string;
}

export interface MFASession {
    token: string; // MFA Code
    userAgent: string;
    fingerprint?: string;
}

const clearSession = () => {
    type Key = keyof typeof AuthLocalStorageKeys;

    // Clear all auth keys on login
    for (const key in AuthLocalStorageKeys) {
        const sessionKey = AuthLocalStorageKeys[key as Key];
        LocalStorage.removeItem(sessionKey);
    }
};

const onLogin = async (userAccount: Partial<ExternalUserAccount>) => {
    clearSession();

    try {
        Auth.target(AuthTargetedTypes.TOKEN, userAccount.access_token);
        Auth.target(AuthTargetedTypes.USER_ACCOUNT, userAccount);

        const { auth } = apiHelper;

        // Fetch the developers apiKey data; Uses the token received from the login above
        let response = await auth(endpoints.keys()).get();
        const apiKey = keysToCamelCase(response.data.api_key) as any; // Fix
        const isDeveloper = apiKey.apiKey !== '';
        const user = {
            loggedIn: true,
            isDeveloper,
            isTier1Developer: isDeveloper && apiKey.tier1Access,
            isTier2Developer: isDeveloper && apiKey.tier2Access,
            account: keysToCamelCase(userAccount.account),
            apiKey,
        };

        Auth.target(AuthTargetedTypes.USER, user);
        Auth.target(AuthTargetedTypes.API_KEY, apiKey.apiKey);

        // Fetch the developers apiKey data; Uses the token received from the login above
        response = await auth(endpoints.machineKeys()).get();
        const machineKeys = keysToCamelCase(response.data.api_keys);
        Auth.target(AuthTargetedTypes.API_KEYS, machineKeys);

        return user;
    } catch (error) {
        Auth.clearData();
        throw error;
    }
};

export const mfaLogin = (data: MFASession) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const session = await accountsApi.mfa.login(data);
            await onLogin(session);
            await dispatch(LoginActions.loginSucceeded(session));
        } catch (error) {
            if (
                (error as any)?.response &&
                (error as any).response.data &&
                (error as any).response.data.error === 'lockout'
            ) {
                dispatch(LoginActions.lockout());
                throw error;
            } else {
                dispatch(LoginActions.loginFailed((error as any)?.response?.data?.error));
                throw error;
            }
        }
    };
};

export const login = LoginActions.makeLogin(onLogin);
