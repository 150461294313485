/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './index.scss';
import '../src/polyfills';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom-v5-compat';

import { LaunchDarklyProvider } from 'sarsaparilla';
import { RestoreScroll } from 'shared-ui/components/RestoreScroll/RestoreScroll';

import { store } from '../src/store';
import App from '../src/App';

const rootElement = document.getElementById('recApp');
if (!rootElement) throw new Error('Failed to find the recApp root element');

const root = createRoot(rootElement);
root.render(
    <div id="developer-portal" className="dev-portal-content">
        <Provider store={store}>
            <BrowserRouter
                future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                }}
            >
                <RestoreScroll />
                <LaunchDarklyProvider>
                    <App />
                </LaunchDarklyProvider>
            </BrowserRouter>
        </Provider>
    </div>
);
