/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { LOGOUT, RELOAD, UPDATE_CURRENT_USER } from '../shared/constants/actions';
import { currentUserDefaults } from '../shared/constants/auth';
import Auth from '../shared/services/auth';
import { IApiKey } from '../shared/types/auth/apiKey';
import { makeAsyncAction } from '../shared/utils/async';

export const reload = makeAsyncAction(RELOAD, (data) => () => Promise.resolve({ data }));

export const logout = makeAsyncAction(LOGOUT, () => () => {
    Auth.clearData();
    return Promise.resolve({ data: currentUserDefaults });
});

export const updateCurrentUser = makeAsyncAction(
    UPDATE_CURRENT_USER,
    (userData: { apiKey: IApiKey }) => () => Promise.resolve({ data: userData })
);
