/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import {
    Button,
    Drawer,
    HelmetWrapper,
    Icon,
    SkipNavLink,
    useBreakpoint,
} from 'sarsaparilla';

import { getCurrentUser } from '../reducers/currentUser';
import HeaderLink from '../shared/components/HeaderLink';
import { apiKeyDefaults } from '../shared/constants/auth';
import * as globals from '../shared/globals';
import AccountInfo from './AccountInfo';

import '../scss/containers/_Header.scss';

import getAssetPath = globals.getAssetPath;

export function Header() {
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = React.useState(false);
    const { isLgAndUp } = useBreakpoint();

    const writeAccess =
        useSelector((state) => getCurrentUser(state))?.apiKey?.writeAccess ||
        apiKeyDefaults;
    const imagePath = getAssetPath('img/recLogoLight.png');
    const logoAltText = `${process.env['SITE_NAME']} logo`;

    const handleMenuToggle = () => {
        setIsMenuDrawerOpen((prevState) => {
            return !prevState;
        });
    };

    return (
        <header id="ridb-header" role="banner" className="ridb-header">
            <SkipNavLink />
            <HelmetWrapper title="RIDB" />
            <div className="logo" aria-label="Navigate to home">
                <Link to="/">
                    <img src={imagePath} alt={logoAltText} />
                    <div className="h6">RIDB {process.env['RIDB_VERSION']}</div>
                </Link>
            </div>
            {isLgAndUp ? (
                <>
                    <HeaderLink to="/" text="Home" />
                    <HeaderLink to="/docs" text="API Documentation" />
                    <HeaderLink to="/download" text="Data Download" />
                    <HeaderLink to="/standards" text="Data Standards" />
                    {writeAccess && (
                        <HeaderLink
                            text="Data Management"
                            menuItemStartActive
                            to="/data-steward"
                        />
                    )}
                    <div className="menu-item">
                        <AccountInfo />
                    </div>
                </>
            ) : (
                <>
                    <Button
                        appearance="icon-white"
                        size="md"
                        iconBeforeElement={<Icon iconName="menu" />}
                        screenReaderTextBefore="Menu"
                        onClick={handleMenuToggle}
                        className="mr-4"
                    />

                    <Drawer
                        isLabelVisible
                        label={`RIDB ${process.env['RIDB_VERSION']}`}
                        direction="right"
                        isOpen={isMenuDrawerOpen}
                        onClose={handleMenuToggle}
                        triggerId="menu-drawer"
                        appearance="dark"
                    >
                        <div className="my-2">
                            <HeaderLink to="/" text="Home" />
                        </div>
                        <div className="my-2">
                            <HeaderLink to="/docs" text="API Documentation" />
                        </div>
                        <div className="my-2">
                            <HeaderLink to="/download" text="Data Download" />
                        </div>
                        <div className="my-2">
                            <HeaderLink to="/standards" text="Data Standards" />
                        </div>
                        {writeAccess && (
                            <div className="my-2">
                                <HeaderLink
                                    text="Data Management"
                                    menuItemStartActive
                                    to="/data-steward"
                                />
                            </div>
                        )}
                        <div className="menu-item">
                            <AccountInfo />
                        </div>
                    </Drawer>
                </>
            )}
        </header>
    );
}

export default Header;
